import React, { useState } from 'react';
import { User, Phone, Mail, Users, X, Share2 } from 'lucide-react';

const LunchForLoveForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    group: '',
  });

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [shareMethod, setShareMethod] = useState('');
  const [shareContact, setShareContact] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    const response = await fetch('/api/submit-form', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setFormSubmitted(true);
    } else {
      console.error('Form submission failed');
    }
  };

  const handleShare = () => {
    setShowShareOptions(true);
  };

  const submitShare = async () => {
    // Handle share submission logic here
    setShowShareOptions(false);
    setShareMethod('');
    setShareContact('');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 p-4">
      <div className="w-full max-w-md bg-white bg-opacity-20 rounded-3xl shadow-2xl overflow-hidden border border-white border-opacity-20 relative">
        <button
          onClick={handleShare}
          className="absolute top-4 right-4 text-white hover:text-white focus:outline-none"
        >
          <Share2 size={24} />
        </button>
        <div className="p-8">
          <h2 className="text-4xl font-extrabold text-white text-center mb-2">Lunch for Love</h2>
          <p className="text-xl text-white text-center mb-6">Make an eternal impact with one lunch per week!</p>
          {!formSubmitted ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="text-white text-opacity-80" />
                </div>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Full Name"
                  className="w-full bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-70 border-0 rounded-lg py-3 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40"
                  required
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone className="text-white text-opacity-80" />
                </div>
                <input 
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number" 
                  className="w-full bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-70 border-0 rounded-lg py-3 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40"
                  required
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="text-white text-opacity-80" />
                </div>
                <input
                  type="email"
                  name="email" 
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  className="w-full bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-70 border-0 rounded-lg py-3 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40"
                  required
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Users className="text-white text-opacity-80" />
                </div>
                <select
                  name="group"
                  value={formData.group}
                  onChange={handleChange}
                  className="w-full bg-white bg-opacity-20 text-white border-0 rounded-lg py-3 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40 appearance-none"
                  required
                >
                  <option value="" disabled>Select Group</option>
                  <option value="men">Men</option>  
                  <option value="women">Women</option>
                  <option value="children">Children</option>
                  <option value="young-adults">Young Adults</option>
                </select>
              </div>
              <div className="flex flex-col items-center space-y-2">
                <button
                  type="submit"
                  className="w-full bg-white bg-opacity-20 hover:bg-opacity-30 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40"
                >
                  Register Now  
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center">
              <p className="text-2xl text-white mb-4">Thank you for registering!</p>
              <p className="text-white mb-6">Confirmation has been sent to your phone.</p>
              <p className="text-white mb-6">Please visit <a href="https://www.jesuslunch.com" target="_blank" rel="noopener noreferrer" className="underline">www.jesuslunch.com</a> for more information.</p>
              <button
                onClick={handleShare}
                className="inline-flex items-center bg-white bg-opacity-20 hover:bg-opacity-30 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40"
              >
                <Share2 className="mr-2" />
                Share with a Friend
              </button>
            </div>
          )}
        </div>
      </div>
      {showShareOptions && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-8 max-w-sm w-full relative">
            <button 
              onClick={() => setShowShareOptions(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <X size={24} />  
            </button>
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Share with a Friend</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Share via</label>
                <select
                  value={shareMethod}
                  onChange={(e) => setShareMethod(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="">Select method</option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                </select>
              </div>
              {shareMethod && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {shareMethod === 'phone' ? 'Phone Number' : 'Email Address'}
                  </label>
                  <input
                    type={shareMethod === 'phone' ? 'tel' : 'email'}  
                    value={shareContact}
                    onChange={(e) => setShareContact(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder={shareMethod === 'phone' ? 'Enter phone number' : 'Enter email address'}
                  />
                </div>
              )}
              <button
                onClick={submitShare}
                className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"  
              >
                Share
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LunchForLoveForm;
